const toggleEyeIcon = $eyeIcon => {
  $eyeIcon.toggleClass('fa-eye-slash');
  $eyeIcon.toggleClass('fa-eye');
};

const toggleInputType = input => {
  input.type === 'password' ? input.type = 'text' : input.type = 'password';
};

$(() => {
  $('.show-password').on('click', e => {
    const input = $(e.currentTarget).parents('.input-group').find('input').get(0);
    toggleInputType(input);
    const $eyeIcon = $(e.currentTarget).find('svg');
    toggleEyeIcon($eyeIcon);
  });
});
