$(function () {
  const fetchAreas = (url, prefecture_id) => {
    return $.ajax({
      url: url,
      type: 'GET',
      data: { 'prefecture_id': prefecture_id }
    });
  };

  $('#prefecture_id').on('change', e => {
    const prefectureId = e.currentTarget.value;
    const $select = $('#area_id');
    const url = $select.data('path');
    $select.children().remove();
    fetchAreas(url, prefectureId)
      .done(response => {
        if (response.length < 1) return false;
        response.forEach(a => {
          $select.append(`<option value="${a.id}">${a.name}</option>`);
        });
        const $firstOption = $select.find('option').first();
        $firstOption.prop('selected', true);
        if ($firstOption.length > 0) {
          $select.parents('form').trigger('submit');
        }
      })
      .fail(response => {
        console.log(response.responseText);
      });
  });
});

$(function () {
  // 派遣先の都道府県のセレクトボックス
  // selected属性を持つoptionが複数あるとき、先頭のoptionを選ぶ
  const selectBox = $("select#prefecture_id");
  const selectedOptions = selectBox.find("option").filter((_, option) => {
    return option.hasAttribute("selected");
  });
  if (selectedOptions.length > 1) {
    selectBox.val(selectedOptions.first().val());
  }
});

$(function () {
  // 派遣先の市町村のセレクトボックス
  // selected属性を持つoptionが複数あるとき、先頭のoptionを選ぶ
  const areaSelectBox = $('select#area_id');
  const selectedOptions = areaSelectBox.find('option').filter((_, option) => {
    return option.hasAttribute('selected');
  });
  if (selectedOptions.length > 1) {
    areaSelectBox.val(selectedOptions.first().val());
  }
});
