$(function () {
  const $select = $('.select2');
  $select.select2({
    width: '100%',
    theme: 'bootstrap4'
  });
  $('.select2.auto-submit').on('select2:select select2:unselect', () => {
    $('form').trigger('submit');
  });
  // bootstrapのバリデーションエラースタイルをselect2にも適用するためにオリジナルクラスを付与する
  $('select.is-invalid').next().find('.select2-selection').addClass('select2-invalid');
});
