$(() => {
  $('#prefecture_or_area_name').on('change', (e) => {
    const searchText = e.currentTarget.value;
    $('.posts-sidebar-link').each((_i, element) => {
      const linkText = $(element).text();
      if (linkText.includes(searchText)) {
        $(element).show();
      } else {
        $(element).hide();
      }
    });
  });
});
